import React from 'react'; // eslint-disable-line import/no-extraneous-dependencies

import styles from './styles.module.css';

import Animation from '../Animation';

const Hero = ({ title, ...props }) => (
  <div className={styles.container} {...props}>
    <div className={styles.animation}>
      <Animation className={styles.svg} />
    </div>
    <div className={styles.message}>
      <h1 className={styles.logo}>
        <img
          src="/images/north_link_logotyp_long.svg"
          alt={title}
          width="270"
          height="71"
        />
      </h1>
      <div className={styles.title}>
        Pushing boundaries - together
      </div>
      <div className={styles.subtitle}>
        To learn what we don’t know. To solve hard challenges. To enjoy the journey.
      </div>
    </div>
  </div>
);

export default Hero;
