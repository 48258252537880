import React from 'react'; // eslint-disable-line import/no-extraneous-dependencies
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import KeyPoints from '../components/KeyPoints';
import IndexCases from '../components/IndexCases';
import IndexProducts from '../components/IndexProducts';

import Location from '../components/Location';
import Logos from '../components/Logos';

import styles from './index.module.css';

const AnimationBackground = () => (
  <div className={styles.animationBackground}>
    <div className={styles.animationBackgroundLeft} />
    <div className={styles.animationBackgroundCenter}>
      <div className={styles.animationBackgroundCenterLeft} />
      <div className={styles.animationBackgroundCenterRight} />
    </div>
    <div className={styles.animationBackgroundRight} />
  </div>
);

const IndexPage = ({ data }) => (
  <div>
    <Helmet
      title={data.site.siteMetadata.title}
      meta={[
        {
          name: 'description',
          content: 'To learn what we don’t know. To solve hard challenges. To enjoy the journey.',
        },
        {
          name: 'keywords',
          content:
            'javascript, js, consulting, big data, hadoop, spark, machine learning, tensor flow, react, angular, web, html, css, design, ux',
        },
      ]}
    >
      <html lang="sv" />
      <link rel="icon" href="/favicon.ico?v=2" />
    </Helmet>
    <div className={styles.container}>
      <section className={styles.background}>
        <AnimationBackground />
        <Header showLogo={false} title={data.site.siteMetadata.title} />
        <Hero title={data.site.siteMetadata.title} />
      </section>
      <main>
        <section>
          <Logos logos={data.allLogosJson.edges.map((x) => x.node)} id="logos" />
        </section>
        <KeyPoints id="keypoints" />
        <IndexCases id="indexcases" />
        <IndexProducts id="indexproducts" />
        <Location title={data.site.siteMetadata.title} id="location" />
      </main>
      <Footer
        products={data.allProductsJson.edges.map((x) => x.node)}
        cases={data.allCasesJson.edges.map((x) => x.node)}
        title={data.site.siteMetadata.title}
      />
    </div>
  </div>
);

export default IndexPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allLogosJson {
      edges {
        node {
          src
          alt
        }
      }
    }
    allCasesJson {
      edges {
        node {
          url
          shortTitle
        }
      }
    }
    allProductsJson {
      edges {
        node {
          url
          shortTitle
        }
      }
    }
  }
`;
