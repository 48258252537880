import React from 'react'; // eslint-disable-line import/no-extraneous-dependencies
import classNames from 'classnames';

import styles from './styles.module.css';

const Animation = ({ className, ...props }) => (
  <div className={classNames(className, styles.container)} {...props}>
    <div className={styles.inner}>
      <img
        src="/images/animation/nl_hero_1.svg"
        alt="animation"
        className={styles.animation1}
      />
      <img
        src="/images/animation/nl_hero_5.svg"
        alt="animation"
        className={styles.animation4}
      />
      <img
        src="/images/animation/nl_hero_3.svg"
        alt="animation"
        className={styles.animation2}
      />
      <img
        src="/images/animation/nl_hero_9.svg"
        alt="animation"
        className={styles.animation3}
      />
      <img
        src="/images/animation/nl_hero_7.svg"
        alt="animation"
        className={styles.animation5}
      />
    </div>
  </div>
);

export default Animation;
