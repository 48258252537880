import React from 'react'; // eslint-disable-line import/no-extraneous-dependencies
import styles from './styles.module.css';

const Box = ({ icon, title, text }) => (
  <div className={styles.box}>
    <img src={icon} />
    <h3>{title}</h3>
    <p>{text}</p>
  </div>
);

const KeyPoints = ({ ...props }) => (
  <section className={styles.container} {...props}>
    <div className={styles.inner}>
      <Box
        icon="/images/nl_icons_front_120x120-01.svg"
        title="Knowledge"
        text="We’re a team of experts in machine learning, computer vision and software development. Constantly learning is a big part of our DNA."
      />
      <Box
        icon="/images/nl_icons_front_120x120-03.svg"
        title="Innovation"
        text="We use our collective knowledge to build software solutions for our customers and to create products. Innovation is key in how we work."
      />
      <Box
        icon="/images/nl_icons_front_120x120-02.svg"
        title="Happiness"
        text="We strive to work with the projects that inspire us. We believe that happiness is the power of our success."
      />
    </div>
  </section>
);

export default KeyPoints;
