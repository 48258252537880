import React from 'react'; // eslint-disable-line import/no-extraneous-dependencies
import Link from 'gatsby-link';

import styles from './styles.module.css';

const IndexProducts = ({ ...props }) => (
  <section className={styles.container} {...props}>
    <div className={styles.inner}>
      <div className={styles.content}>
        <div className={styles.innerContent}>
          <h4>Products</h4>
          <div className={styles.line} />
          <p>
            Chess annotation recording, data visualization and AI art are some of our most interesting projects.
            We are implementing the latest machine learning, big data handling
            and developing solutions for technical challenging problems.
          </p>
          <Link to="/products" className={styles.link}>
            Read all about it >>
          </Link>
        </div>
      </div>
      <div className={styles.illustration}>
        <img src="/images/nl_products.svg" />
      </div>
    </div>
  </section>
);

export default IndexProducts;
