import React from 'react'; // eslint-disable-line import/no-extraneous-dependencies
import Link from 'gatsby-link';

import styles from './styles.module.css';

const IndexCases = ({ ...props }) => (
  <section className={styles.container} {...props}>
    <div className={styles.inner}>
      <div className={styles.illustration}>
        <img src="/images/nl_cases.svg" />
      </div>
      <div className={styles.content}>
        <div className={styles.innerContent}>
          <h4>Cases</h4>
          <div className={styles.line} />
          <p>
            During the years we have worked with a multitude of interesting
            cases that requires our expertise in problem solving. Computer vision, synthetic training data, automatic categorization pipelines, optimization for edge inference and much more.
          </p>
          <Link to="/cases" className={styles.link}>
            Read all about it >>
          </Link>
        </div>
      </div>
    </div>
  </section>
);

export default IndexCases;
