import React from 'react'; // eslint-disable-line import/no-extraneous-dependencies

import styles from './styles.module.css';

const Logos = ({ logos, ...props }) => (
  <div className={styles.container} {...props}>
    <div className={styles.inner}>
      <h2>Trusted By Innovative Companies</h2>
      <div className={styles.logoContainer}>
        {logos.map((logo) => (
          <img key={logo.src} className={styles.logo} src={logo.src} alt={logo.alt} />
        ))}
      </div>
    </div>
  </div>
);

export default Logos;

